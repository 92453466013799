@import '@angular/cdk/overlay-prebuilt.css';
@import '@angular/cdk/a11y-prebuilt.css';
@import '@allianz/ngx-ndbx/css/ndbx-base.css';
@import '@aposin/ng-aquila/css/utilities.css';


@import '@allianz/ngx-ndbx/css/themes/ndbx-expert.css';



html {
  height: 100%;
}

body {
  background-color: var(--ui-background, transparent);
  height: 100%;
}

